/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown, Spinner, Collapse
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { MultiSelect } from "react-multi-select-component";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import { event } from "jquery";

var templateName = ''
var creativeBody = {}
var creativeBodyInfo = []

const TemplateSettings = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [templateChanged, setTemplateChanged] = useState(false)
    console.log(props)

    const [creativeData, setCreativeData] = useState([])
    const [refresh_Intervals, setRefresh_Intervals] = useState([])
    const [selectedDevice, setSelectedDevice] = useState([]);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    const [finalData, setFinalData] = useState({
        highImpactSettingTemplateName: '',
        highImpactSettingData: {}
    })

    useEffect(() => {
        if (props.selectedTemplate) {
            if (props.selectedHighImpact !== '') {
                setTemplateChanged(true)
                if (templateChanged) {
                    props.loaderState(true)
                    setFinalData({
                        highImpactSettingTemplateName: '',
                        highImpactSettingData: {}
                    })
                    creativeBody = {}
                    props.selectedItems({ highImpactSettingData: {} })
                    creativeBodyInfo = []
                    console.log(props.selectedTemplate)
                    handleFreshData(props.selectedTemplate)
                }
            } else {
                props.loaderState(true)
                setTemplateChanged(true)
                setFinalData({
                    highImpactSettingTemplateName: '',
                    highImpactSettingData: {}
                })
                creativeBody = {}
                props.selectedItems({ highImpactSettingData: {} })
                creativeBodyInfo = []
                console.log(props.selectedTemplate)
                handleFreshData(props.selectedTemplate)
            }

            // props.loaderState(true)
            // setTemplateChanged(true)
            // setFinalData({
            //     highImpactSettingTemplateName: '',
            //     highImpactSettingData: {}
            // })
            // creativeBody = {}
            // props.selectedItems({ highImpactSettingData: {} })
            // creativeBodyInfo = []
            // console.log(props.selectedTemplate)
            // handleFreshData(props.selectedTemplate)
        }
    }, [props.selectedTemplate])


    useEffect(() => {
        console.log(props.selectedHighImpact)
        creativeBodyInfo = []
        if (props.selectedHighImpact !== '') {
            props.loaderState(true)
            setFinalData({
                highImpactSettingTemplateName: '',
                highImpactSettingData: {}
            })
            creativeBody = {}
            props.selectedItems({ highImpactSettingData: {} })
            creativeBodyInfo.push(props.selectedHighImpact.highImpactSettingData)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: props.selectedHighImpact.highImpactSettingData }));
            props.selectedItems({ highImpactSettingData: props.selectedHighImpact.highImpactSettingData })
            handleData(props.selectedHighImpact.highImpactSettingTemplateName)
        }
        console.log(creativeBodyInfo)
    }, [])


    const contentChange = index => e => {
        console.log(e.target)
        let newArr = [...creativeData];
        if (e.target.type === 'checkbox') {
            newArr[index].checked = e.target.checked;
            setCreativeData(newArr);
        } else {
            newArr[index].value = e.target.value;
            setCreativeData(newArr);
        }
        let countChecked = 0
        console.log(creativeData)
        creativeData.map((item, index) => {
            const { label, value } = item;
            console.log(item, value)
            let numMul = isNaN(value)
            if (item.variableType === "NUMERIC" && numMul) {
                if (value) {
                    console.log(value)
                    creativeBody = { ...creativeBody, [label]: parseInt(value) }
                }
            } else if (item.variableType === "JSONArray") {
                if (typeof value === "string") {
                    let urls = value.split(/[\s,]+/);
                    creativeBody = { ...creativeBody, [label]: urls }
                } else {
                    creativeBody = { ...creativeBody, [label]: value }
                }
            } else if (item.variableType === "CHECKBOX") {
                if (item.checked) {
                    countChecked = countChecked + 1
                    creativeBody = { ...creativeBody, [label]: item.description }
                } else {
                    creativeBody = { ...creativeBody, [label]: '' }
                }
            }
            else {
                if (value === 'YES') {
                    creativeBody = { ...creativeBody, [label]: true }
                } else if (value === 'NO') {
                    creativeBody = { ...creativeBody, [label]: false }
                } else {
                    creativeBody = { ...creativeBody, [label]: value }
                }
            }
            console.log(creativeBody)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
            props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
        })
        console.log(creativeBody)
        console.log(finalData)
        setCreativeData(modifyData(countChecked, creativeData))
    }

    const IntervalStateChange = (index, i) => e => {
        let newArr = [...creativeData];
        newArr[i].defaultValue[index].intervalState = e.target.checked
        setCreativeData(newArr);
        console.log(creativeData)
        creativeData.map((item, index) => {
            const { label, value } = item;
            console.log(item, value)
            if (item.variableType === "INTERVALS") {
                const intervalsWithStateTrue = newArr[i].defaultValue.filter(item => item.intervalState).map(item => item.interval);
                creativeBody = { ...creativeBody, [label]: intervalsWithStateTrue }
            }
            console.log(creativeBody)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
            props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
        })
    }

    const EventStateChange = (index, i) => e => {
        let newArr = [...creativeData];
        if (e.target.name === 'eventState') {
            newArr[i].defaultValue[index].eventState = e.target.checked

        } else if (e.target.name === 'eventRefresh') {
            newArr[i].defaultValue[index].eventRefresh = e.target.checked
        }
        else {
            const { name, value } = e.target;
            newArr[i].defaultValue[index][name] =value ? parseInt(value) : '' 
        }
        setCreativeData(newArr);
        console.log(creativeData)
        creativeData.map((item, index) => {
            const { label, value } = item;
            console.log(item, value)
            if (item.variableType === "EVENTS_SETTINGS") {
                const eventSettings = newArr[i].defaultValue.filter(event => event.eventState);;
                creativeBody = { ...creativeBody, [label]: eventSettings }
            }
            console.log(creativeBody)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
            props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
        })
    }

    const handleSelectDevice = i => device => {
        let devicesList = []
        device.map((item, index) => {
            devicesList.push(item.label)
        })
        let newArr = [...creativeData];
        newArr[i].value = devicesList;
        setCreativeData(newArr);

        creativeData.map((item, index) => {
            const { label, value } = item;
            if (item.variableType === "EVENTS") {
                creativeBody = { ...creativeBody, [label]: devicesList }
            }
        })
        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
        props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
    }


    const handleData = async (template) => {
        console.log(creativeBody)
        const bodyData = {
            "url": "http://127.0.0.1:2002/v1.0/CreativeTemplates/getVariable",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                creativeTemplateName: template
            }
        }
        axios.post("/v1.0/connectReportAPI", bodyData,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is etttgdfg ==>", res)
                if (res.data.responseData) {
                    setCreativeData([])
                    creativeBody = {}
                    templateName = res.data.responseData.creativeTemplateName
                    console.log(templateName)
                    creativeBody = { ...creativeBody, url: res.data.responseData.url }
                    setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: templateName, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                    props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody }, highImpactSettingTemplateName: templateName })
                    let countChecked = 0
                    res.data.responseData.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        if (props.selectedHighImpact !== '') {
                            if (creativeBodyInfo[0][label] === true) {
                                item.value = 'YES'
                                item.checked = true
                            } else if (creativeBodyInfo[0][label] === false) {
                                item.value = 'NO'
                                item.checked = false
                            } else {
                                item.value = creativeBodyInfo[0][label]
                            }
                            if (item.variableType === "CHECKBOX") {
                                if (creativeBodyInfo[0][label] !== '') {
                                    item.checked = true
                                    item.isDisabled = false
                                } else if (creativeBodyInfo[0][label] === '') {
                                    item.checked = false
                                    item.isDisabled = false
                                }
                            }
                            if (item.variableType === "INTERVALS") {
                                // console.log(item.defaultValue, creativeBodyInfo[0][label])
                                item.defaultValue = item.defaultValue.map(item => ({
                                    ...item, intervalState: creativeBodyInfo[0][label] ? creativeBodyInfo[0][label].includes(item.interval) : false
                                }));
                            }
                            if (item.variableType === "EVENTS_SETTINGS") {
                                item.defaultValue = item.defaultValue.map(item => ({
                                    ...item, intervalState: creativeBodyInfo[0][label] ? creativeBodyInfo[0][label].includes(item.interval) : false
                                }));
                                item.defaultValue = item.defaultValue.map((defaultItem) => {
                                    const matchingSetting = creativeBodyInfo[0][label] ? creativeBodyInfo[0][label].find(
                                        (setting) => setting.eventType === defaultItem.eventType
                                    ) : [];
                                    return matchingSetting ? { ...defaultItem, ...matchingSetting } : defaultItem;
                                });
                            }
                            if (item.variableType === "EVENTS") {

                                item.value = creativeBodyInfo[0][label] ? creativeBodyInfo[0][label] : [
                                    "touchstart",
                                    "touchend",
                                    "touchmove",
                                    "click",
                                    "keypress",
                                    "keydown",
                                    "keyup"
                                ]
                            }
                   
                        } else {
                            item.value = item.defaultValue ? item.defaultValue : ''
                            if (item.variableType === "CHECKBOX") {
                                item.checked = false;
                                item.isDisabled = false
                            }
                        }
                        if (label.includes('url')) {
                            item.isUrlContains = false
                        }

                    })
                    setCreativeData(modifyData(countChecked, res.data.responseData.creativeTemplateVariables));
                    res.data.responseData.creativeTemplateVariables.map((item, index) => {
                        if (item.variableType === "CHECKBOX") {
                            const { label, checked } = item;
                            creativeBody = { ...creativeBody, [label]: checked }
                        } else {
                            const { label, value } = item;
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        const { label, value } = item;
                        if (value === 'YES') {
                            creativeBody = { ...creativeBody, [label]: true }
                        } else if (value === 'NO') {
                            creativeBody = { ...creativeBody, [label]: false }
                        } else {
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        console.log(item)
                        console.log(creativeBody)
                        // debugger
                        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                        props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
                        console.log(finalData)
                    })
                    props.loaderState(false)
                    console.log("templatedataeeeee" + JSON.stringify(res.data.responseData.creativeTemplateVariables))
                    setCreativeData(modifyData(countChecked, res.data.responseData.creativeTemplateVariables));
                }
            })
            .catch((err) => {
                props.loaderState(false)
                console.log("e", err)
            });
    }



    const handleFreshData = async (template) => {
        console.log("templatedataeeeee" + JSON.stringify(creativeBody), creativeBodyInfo, finalData.highImpactSettingData, props.selectedHighImpact)
        console.log(creativeBody)
        setCreativeData([])
        creativeBodyInfo = []
        creativeBody = {}
        const bodyData = {
            "url": "http://127.0.0.1:2002/v1.0/CreativeTemplates/getVariable",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                creativeTemplateName: template
            }
        }
        axios.post("/v1.0/connectReportAPI", bodyData,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is etttgdfg ==>", res)
                if (res.data.responseData) {
                    templateName = res.data.responseData.creativeTemplateName
                    console.log(templateName)
                    creativeBody = { ...creativeBody, url: res.data.responseData.url }
                    setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: templateName, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                    props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody }, highImpactSettingTemplateName: templateName })
                    let countChecked = 0
                    res.data.responseData.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        item.value = item.defaultValue ? item.defaultValue : ''
                        if (item.variableType === "EVENTS") {
                            item.value = [
                                "touchstart",
                                "touchend",
                                "touchmove",
                                "click",
                                "keypress",
                                "keydown",
                                "keyup"
                            ]
                        }
                        if (item.variableType === "EVENTS_SETTINGS") {
                            item.value = []
                        }
                        if (item.variableType === "INTERVALS") {
                            item.value = ["30"]
                        }
                        if (item.variableType === "CHECKBOX") {
                            item.checked = false;
                            item.isDisabled = false
                        }
                        if (item.variableType === "JSONArray") {
                            item.value = [""]
                        }
                        if (label.includes('url')) {
                            item.isUrlContains = false
                        }
                    })
                    console.log(res.data.responseData.creativeTemplateVariables)
                    setCreativeData(modifyData(countChecked, res.data.responseData.creativeTemplateVariables));
                    res.data.responseData.creativeTemplateVariables.map((item, index) => {
                        if (item.variableType === "CHECKBOX") {
                            const { label, checked } = item;
                            creativeBody = { ...creativeBody, [label]: checked }
                        } else {
                            const { label, value } = item;
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        const { label, value } = item;
                        if (value === 'YES') {
                            creativeBody = { ...creativeBody, [label]: true }
                        } else if (value === 'NO') {
                            creativeBody = { ...creativeBody, [label]: false }
                        } else {
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        console.log(item)
                        console.log(creativeBody)
                        // debugger
                        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                        props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
                        console.log(finalData)
                    })
                    props.loaderState(false)
                }
            })
            .catch((err) => {
                props.loaderState(false)
                console.log("e", err)
            });
    }


    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
    };

    const modifyData = (count, Variabledata) => {
        console.log(Variabledata)
        Variabledata.map((item, index) => {
            index = index + 1
            if (item.variableType === "CHECKBOX") {
                if (count === 5) {
                    if (item.checked) {
                        item.isDisabled = false
                    } else {
                        item.isDisabled = true
                    }
                } else {
                    item.isDisabled = false
                }
            }
            if (index > 4 && count === 5 && index < Variabledata.length) {
                // console.log(index)
                // console.log(Variabledata[index])
                // console.log(Variabledata[index-1])
                if ((Variabledata[index].description).includes(Variabledata[index - 1].description)) {
                    if (Variabledata[index - 1].variableType === "CHECKBOX" && !Variabledata[index - 1].checked) {
                        Variabledata[index].isUrlContains = true
                    }
                }
            } else {
                if (index < Variabledata.length && (Variabledata[index].description).includes(Variabledata[index - 1].description)) {
                    Variabledata[index].isUrlContains = false
                }
            }
        })
        return Variabledata
    }

    const modifyIntervalData = (options, i) => {
        return options.map((elemInt, index) => {
            return (
                <div key={index}>
                    <div className="form-check form-switch form-check-success form-switch-md  mt-1">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"customSwitchsizemd32" + elemInt.interval}
                            checked={elemInt.intervalState}
                            onChange={IntervalStateChange(index, i)}
                            disabled={index === 0}
                        />
                        <label className="form-check-label" htmlFor={"customSwitchsizemd32" + elemInt.interval}>
                            {elemInt.intervalState ? (
                                <span style={{ color: '#34c38f' }}>
                                    Enabled: {elemInt.interval}
                                </span>
                            ) : (
                                <span style={{ color: '#a3a3a3' }}>
                                    Disabled: {elemInt.interval}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
            );
        });
    };

    // const modifyEventsData = (options, i) => {
    //     return options.map((elemInt, index) => {
    //         return (
    //             <div key={index}>
    //                 <div className="row">
    //                     <div className="col-12">
    //                         <div className="mt-4">
    //                             <Label className="form-label">Event Type</Label>
    //                             <div className="form-check form-switch form-check-success form-switch-md  mt-1">
    //                                 <input
    //                                     type="checkbox"
    //                                     className="form-check-input"
    //                                     name="eventState"
    //                                     id={"customSwitchsizemd32" + elemInt.eventType}
    //                                     checked={elemInt.eventState}
    //                                     onChange={EventStateChange(index, i)}
    //                                 />
    //                                 <label className="form-check-label" htmlFor={"customSwitchsizemd32" + elemInt.eventType}>
    //                                     {elemInt.eventState ? (
    //                                         <span style={{ color: '#34c38f' }}>
    //                                             Enabled : {elemInt.eventType}
    //                                         </span>
    //                                     ) : (
    //                                         <span style={{ color: '#a3a3a3' }}>
    //                                             Disabled : {elemInt.eventType}
    //                                         </span>
    //                                     )}
    //                                 </label>

    //                             </div>
    //                         </div>
    //                     </div>
    //                     {elemInt.eventState &&
    //                         <>
    //                             <div className="col-2">
    //                                 <div className="mt-4">
    //                                     <Label className="form-label">Priority</Label>
    //                                     <Input
    //                                         type="number" name="eventPrority" value={elemInt.eventPrority ? parseInt(elemInt.eventPrority) : ''} onChange={EventStateChange(index, i)} className='form-control' readOnly={props.selectedArchived} onWheel={(e) => e.currentTarget.blur()}
    //                                     />
    //                                 </div>
    //                             </div>
    //                             <div className="col-2">
    //                                 <div className="mt-4">
    //                                     <Label className="form-label">Weightage</Label>
    //                                     <Input
    //                                         type="number" name="eventWeight" value={elemInt.eventWeight ? parseInt(elemInt.eventWeight) : ''} onChange={EventStateChange(index, i)} className='form-control' readOnly={props.selectedArchived} onWheel={(e) => e.currentTarget.blur()}
    //                                     />
    //                                 </div>
    //                             </div>
    //                             <div className="col-2">
    //                                 <div className="mt-4">
    //                                     <Label className="form-label">Refresh</Label>
    //                                     <div className="form-check form-switch form-check-success form-switch-md  mt-1">
    //                                         <input
    //                                             type="checkbox"
    //                                             className="form-check-input"
    //                                             name="eventRefresh"
    //                                             id={"customSwitchsizemd323" + elemInt.eventType}
    //                                             checked={elemInt.eventRefresh}
    //                                             onChange={EventStateChange(index, i)}
    //                                             disabled={index === 0}
    //                                         />
    //                                         <label className="form-check-label" htmlFor={"customSwitchsizemd323" + elemInt.eventType}>
    //                                             {elemInt.eventRefresh ? (
    //                                                 <span style={{ color: '#34c38f' }}>
    //                                                     YES
    //                                                 </span>
    //                                             ) : (
    //                                                 <span style={{ color: '#a3a3a3' }}>
    //                                                     NO
    //                                                 </span>
    //                                             )}
    //                                         </label>

    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="col-2">
    //                                 <div className="mt-4">
    //                                     <Label className="form-label">Refresh Count</Label>
    //                                     <Input
    //                                         type="number" name="eventRefreshCount" value={elemInt.eventRefreshCount ? parseInt(elemInt.eventRefreshCount) : ''} onChange={EventStateChange(index, i)} className='form-control' readOnly={props.selectedArchived || !elemInt.eventRefresh} onWheel={(e) => e.currentTarget.blur()}
    //                                     />
    //                                 </div>
    //                             </div>
    //                         </>
    //                     }
    //                 </div>
    //             </div>
    //         );
    //     });
    // };

    const modifyEventsData = (options, i) => {
        // Chunk the options into groups of 3
        const chunkedOptions = [];
        for (let j = 0; j < options.length; j += 3) {
            chunkedOptions.push(options.slice(j, j + 3));
        }
    
        return chunkedOptions.map((chunk, chunkIndex) => (
            <div key={chunkIndex}>
                {chunk.map((elemInt, index) => (
                    <div key={index} className="row mt-4">
                        <div className="col-12">
                            <div className="form-check form-switch form-check-success form-switch-md mb-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="eventState"
                                    id={"customSwitch" + elemInt.eventType}
                                    checked={elemInt.eventState}
                                    onChange={EventStateChange(index, i)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={"customSwitch" + elemInt.eventType}
                                >
                                    {/* <strong>Event Type:</strong> {elemInt.eventType} &nbsp;&nbsp; */}
                                    {elemInt.eventState ? (
                                        <span style={{ color: '#34c38f' }}>{elemInt.eventType}</span>
                                    ) : (
                                        <span style={{ color: '#a3a3a3' }}>{elemInt.eventType}</span>
                                    )}
                                </label>
                            </div>
                        </div>
    
                        {elemInt.eventState && (
                            <>
                                <div className="col-3">
                                    <Label className="form-label">Priority</Label>
                                    <Input
                                        type="number"
                                        name="eventPrority"
                                        value={elemInt.eventPrority ? parseInt(elemInt.eventPrority) : ''}
                                        onChange={EventStateChange(index, i)}
                                        className="form-control"
                                        readOnly={props.selectedArchived}
                                        onWheel={(e) => e.currentTarget.blur()}
                                    />
                                </div>
                                <div className="col-3">
                                    <Label className="form-label">Weightage</Label>
                                    <Input
                                        type="number"
                                        name="eventWeight"
                                        value={elemInt.eventWeight ? parseInt(elemInt.eventWeight) : ''}
                                        onChange={EventStateChange(index, i)}
                                        className="form-control"
                                        readOnly={props.selectedArchived}
                                        onWheel={(e) => e.currentTarget.blur()}
                                    />
                                </div>
                                <div className="col-3">
                                    <Label className="form-label">Refresh</Label>
                                    <div className="form-check form-switch form-check-success form-switch-md">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="eventRefresh"
                                            id={"customSwitchRefresh" + elemInt.eventType}
                                            checked={elemInt.eventRefresh}
                                            onChange={EventStateChange(index, i)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={"customSwitchRefresh" + elemInt.eventType}
                                        >
                                            {elemInt.eventRefresh ? (
                                                <span style={{ color: '#34c38f' }}>YES</span>
                                            ) : (
                                                <span style={{ color: '#a3a3a3' }}>NO</span>
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <Label className="form-label">Refresh Count</Label>
                                    <Input
                                        type="number"
                                        name="eventRefreshCount"
                                        value={elemInt.eventRefreshCount ? parseInt(elemInt.eventRefreshCount) : ''}
                                        onChange={EventStateChange(index, i)}
                                        className="form-control"
                                        readOnly={props.selectedArchived || !elemInt.eventRefresh}
                                        onWheel={(e) => e.currentTarget.blur()}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        ));
    };
    
    
    



    const createUI = () => {
        console.log(creativeData)
        return creativeData.map((item, i) =>
            <div key={i}>
                {item.variableType === "STRING" &&
                    <div className="mt-4" >
                        <Label className="form-label">{item.description}</Label>
                        {item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}
                            />
                        }
                        {!item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}
                            />
                        }

                    </div>
                }
                {item.variableType === "NUMERIC" &&
                    <div className="mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="number" name={item.label} value={item.value ? parseInt(item.value) : ''} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived} onWheel={(e) => e.currentTarget.blur()}
                        />
                    </div>
                }
                {item.variableType === "LONG TEXT" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "JSONArray" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "LIST" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="select"
                            className="form-select"
                            onChange={contentChange(i)}
                            name={item.label} value={item.value} disabled={props.selectedArchived}
                        >
                            {/* <option value=''> Select {item.label}</option> */}
                            {item.choices.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </Input>
                    </div>
                }
                {item.variableType === "INTERVALS" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {modifyIntervalData(item.defaultValue, i)}
                        </div>
                    </div>
                }
                {item.variableType === "EVENTS" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <MultiSelect
                            options={item.defaultValue.map(item => ({
                                label: item.label,
                                value: item.label
                            }))}
                            value={item.value.map(item => ({
                                label: item,
                                value: item
                            }))}
                            onChange={handleSelectDevice(i)}
                            overrideStrings={{ "selectSomeItems": "Please Browser Events" }}
                            // className="col-8"
                            disabled={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "EVENTS_SETTINGS" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {modifyEventsData(item.defaultValue, i)}
                        </div>
                    </div>
                }
                {item.variableType === "CHECKBOX" &&
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.checked}
                            disabled={props.selectedArchived || item.isDisabled}
                            onChange={contentChange(i)}
                        />
                        <label
                            className="form-check-label"
                        >
                            {item.description}
                        </label>
                    </div>
                }
            </div>
        )
    }

    return (
        <React.Fragment>

            <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                <Col xs={8}>
                    {props.selectedTemplate === "" ?
                        <h5 className="mb-4">Please Select Ad formats</h5> :
                        <div className="mb-3" >
                            <h5 className="mb-4">{templateName} Settings</h5>
                            <hr></hr>
                            <div className="mb-3">
                                {createUI()}
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
};

TemplateSettings.propTypes = {}

export default TemplateSettings